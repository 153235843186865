import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchNotifications } from 'reducers/notificationsReducer';
import { BIS, OPEN_DATA } from 'data/dataSourceConstants';
import { openModal } from 'reducers/modalReducer';
import { MODAL_TYPES } from 'view/ModalManager';

import { getAlertDataSource, getAlertDataSet } from 'utils/alertUtils';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';
import OutboundLink from 'components/OutboundLink';


//button for opening alert record details
function RecordDetailsLink({ notification }) {
	const dispatch = useDispatch();
	const dataSource = getAlertDataSource(notification?.notification);
	const dataSet = getAlertDataSet(notification?.notification);
	const recordId = notification?.item_id;

	if (dataSource === BIS && notification?.url) {
		return (<OutboundLink className="mx-2" url={notification.url}><i className="bi bi-info-circle" /></OutboundLink>);
	} else if (dataSource === OPEN_DATA && dataSet && recordId) {
		return (
			<button
				className="btn-hyperlink mx-2"
				onClick={() => dispatch(openModal(MODAL_TYPES.RECORD_DETAILS, { dataSet, id: recordId }))}
			>
				<i className="bi bi-info-circle" />
			</button>
		);
	}

	return <span className="mx-2">-</span>;
}


const AlertsTable = (props) => {
	const dispatch = useDispatch();
	const { hidePaginator, rpp } = props;
	let {
		userId,
		notifications,
		isLoading,
		currentPage,
		totalPages,
	} = useSelector(state => ({
		userId: state.user.userData?.id,
		notifications: state.notifications.notificationsData?.data,
		isLoading: state.notifications.isLoading,
		currentPage: state.notifications.notificationsData?.current_page,
		totalPages: state.notifications.notificationsData?.last_page,
	}));

	useEffect(() => {
		if (userId) {
			if (rpp) {
				dispatch(fetchNotifications(userId, 'created_at|desc', 1, rpp));
			} else {
				dispatch(fetchNotifications(userId));
			}
		}
		
	}, [userId, rpp, dispatch]);

	return (
		<div>
			{isLoading ? <Spinner /> : 
				<div className="pb-4">
					<div className="dashboard-table-wrapper">
						<table className="table table-striped table-rounded rd-table">
							<thead><tr>
								<th />
								<th>Property</th>
								<th>Notification</th>
								<th>Notification Type</th>
								<th>Time</th>
							</tr></thead>
							<tbody>
								{(!notifications || !notifications.length) && (
									<tr><td colSpan={5}>No alerts</td></tr>
								)}
								{notifications?.map((item) => (
									<tr key={item.id}>
										<td className="dashboard-row-link-cell"><RecordDetailsLink notification={item} /></td>
										<td className="dashboard-table-property-address-cell">
											{item.property_name?.address ? (
												<Link to={`/property/${item.property_name?.address}`}>{item.property_name?.address}</Link>
											) : '-'}
										</td>
										<td>{item.notification || '-'}</td>
										<td className="dashboard-notification-type-cell">{item.notification_type?.name || '-'}</td>
										<td className="dashboard-date-time-cell">{item.created_at || '-'}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{(totalPages > 1 && !hidePaginator) &&
						<Paginator
							currentPage={currentPage}
							totalPages={totalPages}
							setPage={(pageNum) => dispatch(fetchNotifications(userId, 'created_at|desc', pageNum, 10))}
						/>						
					}
				</div>
			}
		</div>
	);	


}

export default AlertsTable;
