// import { dataConfig } from 'data/dataConfig';
import { BIS, OPEN_DATA } from 'data/dataSourceConstants';


/*
|App\Mail\BISDOBViolationStatus                       | dobViolations BIS
|App\Mail\NewODDobNowBuildJobApplicationFilingsAlert  | dobJobApplications OPEN_DATA
|App\Mail\NewOdDobComplaintsAlert                     | dobComplaints OPEN_DATA
|App\Mail\UpdatedODDobViolationAlert                  | dobViolations OPEN_DATA
|App\Mail\NewODDOBNowBuildApprovedPermitsAlert        | approvedPermits OPEN_DATA
|App\Mail\NewODDobNowElectricalPermitApplicationsAlert| electricalPermitApplications OPEN_DATA
|App\Mail\NewOdHpdHousingViolationsAlert              | hpdHousingViolations OPEN_DATA
|App\Mail\UpdatedODDOBPermitIssuanceAlert             | permitIssuances OPEN_DATA
|App\Mail\NewOdHpdComplaintsAlert                     | hpdComplaints OPEN_DATA
|App\Mail\NewOdTOOComplaintsAlert                     | complaints311 OPEN_DATA
|App\Mail\BISECBViolationStatus                       | ecbViolations BIS
|App\Mail\NewODDobNowSafetyBoilersAlert               | dobNowSafetyBoilers OPEN_DATA
|App\Mail\NewODDobNowFacadesAlert                     | dobNowSafetyFacades OPEN_DATA
|App\Mail\newBISECBViolation                          | ecbViolations BIS
|App\Mail\NewOdLiquorLicenseAlert                     | slaLiquorLicenses OPEN_DATA
|App\Mail\newBISDOBViolation                          | dobViolations BIS
|App\Mail\NewOdHpdHousingRepairVacateOrdersAlert      | repairVacateOrders OPEN_DATA
*/

/*
	IMPORTANT
	NewOdLiquorLicenseAlert is for the old liquor license dataset, which is no longer supported in the UI
*/

export const stripAlert = (alertString) => {
	const alert = alertString?.split('\\')?.[2];
	return alert || null;
}

export const getAlertDataSource = (alertString) => {
	const alert = stripAlert(alertString);
	return DATA_SOURCE_BY_ALERT[alert] || null;
};

export const getAlertDataSet = (alertString) => {
	const alert = stripAlert(alertString);
	if (alert === 'NewOdLiquorLicenseAlert') {
		//this alert type is for the legacy liquor license dataset that is no longer in the UI
		//the new liquor license dataset does not have email alerts
		return null;
	}

	return DATA_SET_BY_ALERT[alert] || null;
};

export const DATA_SOURCE_BY_ALERT = {
	BISDOBViolationStatus: BIS,
	NewODDobNowBuildJobApplicationFilingsAlert: OPEN_DATA,
	NewOdDobComplaintsAlert: OPEN_DATA,
	UpdatedODDobViolationAlert: OPEN_DATA,
	NewODDOBNowBuildApprovedPermitsAlert: OPEN_DATA,
	NewODDobNowElectricalPermitApplicationsAlert: OPEN_DATA,
	NewOdHpdHousingViolationsAlert: OPEN_DATA,
	UpdatedODDOBPermitIssuanceAlert: OPEN_DATA,
	NewOdHpdComplaintsAlert: OPEN_DATA,
	NewOdTOOComplaintsAlert: OPEN_DATA,
	BISECBViolationStatus: BIS,
	NewODDobNowSafetyBoilersAlert: OPEN_DATA,
	NewODDobNowFacadesAlert: OPEN_DATA,
	newBISECBViolation: BIS,
	NewOdLiquorLicenseAlert: OPEN_DATA,
	newBISDOBViolation: BIS,
	NewOdHpdHousingRepairVacateOrdersAlert: OPEN_DATA,
};

export const DATA_SET_BY_ALERT = {
	BISDOBViolationStatus: 'dobViolations',
	NewODDobNowBuildJobApplicationFilingsAlert: 'dobJobApplications',
	NewOdDobComplaintsAlert: 'dobComplaints',
	UpdatedODDobViolationAlert: 'dobViolations',
	NewODDOBNowBuildApprovedPermitsAlert: 'approvedPermits',
	NewODDobNowElectricalPermitApplicationsAlert: 'electricalPermitApplications',
	NewOdHpdHousingViolationsAlert: 'hpdHousingViolations',
	UpdatedODDOBPermitIssuanceAlert: 'permitIssuances',
	NewOdHpdComplaintsAlert: 'hpdComplaints',
	NewOdTOOComplaintsAlert: 'complaints311',
	BISECBViolationStatus: 'ecbViolations',
	NewODDobNowSafetyBoilersAlert: 'dobNowSafetyBoilers',
	NewODDobNowFacadesAlert: 'dobNowSafetyFacades',
	newBISECBViolation: 'ecbViolations',
	NewOdLiquorLicenseAlert: 'slaLiquorLicenses',
	newBISDOBViolation: 'dobViolations',
	NewOdHpdHousingRepairVacateOrdersAlert: 'repairVacateOrders',
};

// export const EMAIL_ALERT_STRINGS = {
// 	BISDOBViolationStatus: 'BISDOBViolationStatus',
// 	NewODDobNowBuildJobApplicationFilingsAlert: 'NewODDobNowBuildJobApplicationFilingsAlert',
// 	NewOdDobComplaintsAlert: 'NewOdDobComplaintsAlert',
// 	UpdatedODDobViolationAlert: 'UpdatedODDobViolationAlert',
// 	NewODDOBNowBuildApprovedPermitsAlert: 'NewODDOBNowBuildApprovedPermitsAlert',
// 	NewODDobNowElectricalPermitApplicationsAlert: 'NewODDobNowElectricalPermitApplicationsAlert',
// 	NewOdHpdHousingViolationsAlert: 'NewOdHpdHousingViolationsAlert',
// 	UpdatedODDOBPermitIssuanceAlert: 'UpdatedODDOBPermitIssuanceAlert',
// 	NewOdHpdComplaintsAlert: 'NewOdHpdComplaintsAlert',
// 	NewOdTOOComplaintsAlert: 'NewOdTOOComplaintsAlert',
// 	BISECBViolationStatus: 'BISECBViolationStatus',
// 	NewODDobNowSafetyBoilersAlert: 'NewODDobNowSafetyBoilersAlert',
// 	NewODDobNowFacadesAlert: 'NewODDobNowFacadesAlert',
// 	newBISECBViolation: 'newBISECBViolation',
// 	NewOdLiquorLicenseAlert: 'NewOdLiquorLicenseAlert',
// 	newBISDOBViolation: 'newBISDOBViolation',
// 	NewOdHpdHousingRepairVacateOrdersAlert: 'NewOdHpdHousingRepairVacateOrdersAlert',
// };
